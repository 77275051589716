import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Input, Form, Upload, Button, DatePicker, notification } from 'antd';
import axios from "axios";
import { UploadOutlined } from '@ant-design/icons';
import EditSubCategoryModal from './EditSubCategory';
import moment from 'moment';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DOMPurify from 'dompurify';


const SubCategory = () => {

	const token = localStorage.getItem('token');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [fileid, setUploadfiles] = useState([]);
	const [fileList, setFileList] = useState([]); // To store uploaded files
	const [fileList2, setFileList2] = useState([]);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [eventlink, setEventlink] = useState('');
	const [date, setDate] = useState('');
	const [categoryList, setCategoriesList] = useState([]);
	const [selectedCategoryId, setSelectedCategoryId] = useState('');

	const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing

	// Function to open the edit modal
	const handleEditCategory = (category) => {
		function getAllFileIds(category) {
			// Check if the 'files' property exists in the category object
			if (category && category.files && Array.isArray(category.files)) {
				// Map over the array and extract the fileId property
				return category.files.map(file => file.fileid);
			} else {
				// Handle the case where 'files' property is missing or not an array
				console.error("Invalid category object or missing 'files' property.");
				return [];
			}
		}
		let fileIds = getAllFileIds(category);
		sessionStorage.setItem('fileids', JSON.stringify(fileIds));
		setSelectedCategory(category);
		setShowEditModal(true);
	};
	const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	};
	const handleDeleteCategory = async (eventId) => {
		try {
			const confirmDelete = window.confirm("Are you sure you want to delete this event?");

			if (!confirmDelete) {
				// If the user cancels, do nothing
				return;
			}
			setLoading(true);

			// Make an API request to delete the category
			const response = await axios.post(
				`https://api.nagaratharcoc.com/api/v1/event/delete`,
				{
					eventid: eventId, // Pass the categoryId as a payload
				},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
						'Content-Type': 'application/json',
					},
				}
			);


			// Check if the deletion was successful
			if (response.status === 200) {
				// The category was deleted successfully
				console.log('Category deleted successfully');

				// Reload the page to reflect the changes
				window.location.reload();
			} else {
				console.error('Failed to delete category');
			}

			setLoading(false);
		} catch (error) {
			console.error('Error deleting category:', error);
			setLoading(false);
		}
	};
	// Function to close the edit modal
	const handleSaveEdit = async (editedData) => {
		try {
			setLoading(true);
			editedData.eventid = selectedCategory.eventid;
			editedData.categoryname = selectedCategory.categoryname;
			delete editedData.files;
			// Make an API request to update the subcategory data
			const response = await axios.post(
				`https://api.nagaratharcoc.com/api/v1/event/add`,
				editedData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'application/json',
					},
				}
			);

			if (response.status === 401 || response.status === 404) {
				// Redirect to the login page for 401 or 404 errors
				navigate('/AdminLogin');
				return;
			}

			if (response.status === 200) {
				console.log('Subcategory updated successfully');
				window.location.reload();
				// Optionally, you can update your component state or perform other actions here
			} else {
				console.error('PUT request failed');
			}
		} catch (error) {
			console.error('Error updating data:', error);
		} finally {
			setLoading(false);
			//   handleCloseEditModal(); // Close the modal whether the request succeeds or fails
		}
	};

	useEffect(() => {
		const fetchCategoryNames = async () => {
			try {
				const response = await axios.put("https://api.shopeasey.com/api/v1/category/get", {}, {
					headers: {
						'Authorization': 'Bearer ' + token,
						'Content-Type': 'application/json',
					},
				});
				if (response.status === 401 || response.status === 404) {
					// Redirect to the login page for 401 or 404 errors
					navigate('/AdminLogin');
					return; // Exit early to prevent further logic execution
				}
				setCategoriesList(response.data);
			} catch (error) {
				console.error("Error fetching names:", error);
			}
		};

		fetchCategoryNames();

	}, []);

	useEffect(() => {
		if (!token) {
			// Redirect to the login page if the token is not present
			navigate('/AdminLogin');
			return;
		}
		fetchData();
	}, []); // Empty dependency array ensures the effect runs only once

	const fetchData = async () => {
		try {
			const response = await axios.put("https://api.nagaratharcoc.com/api/v1/event/get", {}, {

				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
			});
			if (response.status === 401 || response.status === 404) {
				// Redirect to the login page for 401 or 404 errors
				navigate('/AdminLogin');
				return; // Exit early to prevent further logic execution
			}
			setData(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};


	const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');
		const cleanedFileId = String(fileid).replace(/,/g, '');
		const cleanedFileIds2 = fileList2 ? fileList2.map((fileid) => String(fileid)) : [];
		const organizerIds = JSON.parse(localStorage.getItem('organizerIds')) || [];
		try {
			setLoading(true);
			// TODO: Replace with actual API call
			const response = await fetch("https://api.nagaratharcoc.com/api/v1/event/add", {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					title,
					description,
					eventlink,
					fileid: cleanedFileId,
					fileids: organizerIds,
					date,
				}),
			});

			if (response.status === 401 || response.status === 404) {
				// Redirect to the login page for 401 or 404 errors
				navigate('/login');
				return; // Exit early to prevent further logic execution
			}

			if (response.ok) {
				const data = await response.json();
				const token = data.token;
				console.log("POST request successful");
				if (response.data && response.data.length > 0) {
					setData(response.data);
				} else {
					setData([]);
				}
				setLoading(false);
				localStorage.removeItem('organizerIds');
				window.location.reload(); // Refresh the page
				if (data.token) {
					localStorage.setItem('token', token);
				}
			} else {
				console.error("POST request failed");
			}
		} catch (error) {
			console.error("Error posting data:", error);
			setLoading(false);
		}
	};
	const handleFileUpload = async (options) => {
		const { onSuccess, onError, file, onProgress } = options;
		const maxFileSize = 1024 * 1024;
		if (file.size > maxFileSize) {
			notification.error({
				message: 'Please upload an image with a size less than 1MB.',
			});
			return;
		}
		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post("https://api.nagaratharcoc.com/api/v1/fileupload/file", formData, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);
					onProgress({ percent: percentCompleted });
				},
				headers: {
					Authorization: 'Bearer ' + token, // Set the Authorization header with the token
					// 'Content-Type': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			}
			);

			if (response.status === 401 || response.status === 404 || !token) {
				// Redirect to the login page for 401 or 404 errors or if the token is not present
				navigate('/AdminLogin');
				return; // Exit early to prevent further logic execution
			}

			setUploadfiles((prevUploadFiles) => [...prevUploadFiles, response.data.organiserId]);
			onSuccess("File uploaded");
		} catch (error) {
			onError("File upload failed");
		}
	};

	const handleFileUploadforMultipleImages = async (options, fileListStateSetter) => {
		const { onSuccess, onError, file, onProgress } = options;
		const maxFileSize = 1024 * 1024;
		if (file.size > maxFileSize) {
			notification.error({
				message: 'Please upload an image with a size less than 1MB.',
			});
			return;
		}
		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post(
				"https://api.nagaratharcoc.com/api/v1/fileupload/file",
				formData,
				{
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						onProgress({ percent: percentCompleted });
					},
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			if (response.status === 401 || response.status === 404 || !token) {
				navigate('/AdminLogin');
				return;
			}
			const organizerId = response.data.organiserId;

			// Assuming you have an array in localStorage to store organizerIds
			const storedOrganizerIds = JSON.parse(localStorage.getItem('organizerIds')) || [];
			storedOrganizerIds.push(organizerId);

			// Save the updated array back to localStorage
			localStorage.setItem('organizerIds', JSON.stringify(storedOrganizerIds));
			fileListStateSetter((prevFileList) => [...prevFileList, response.data.organiserId]);
			onSuccess("File uploaded");
		} catch (error) {
			onError("File upload failed");
		}
	};

	return (
		<div class="wrapper">

			{/* <!-- LEFT MAIN SIDEBAR --> */}
			<SideBar />

			{/* <!-- PAGE WRAPPER --> */}
			<div class="ec-page-wrapper">

				{/* <!-- Header --> */}
				<Header />

				{/* <!-- CONTENT WRAPPER --> */}
				<div class="ec-content-wrapper">
					<div class="content" style={{ marginTop: "50px" }}>
						<div class="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Events</h1>
							<p class="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i class="mdi mdi-chevron-right"></i></span>Events</p>
						</div>
						<div class="row">
							<div class="col-xl-4 col-lg-12">
								<div class="ec-cat-list card card-default mb-24px">
									<div class="card-body">
										<div class="ec-cat-form">
											<h4>Add Events</h4>

											<form
												onSubmit={onFinish}
											>


												<div className="col-md-12">
													<label className="form-label" name="fileid">Upload Images</label>
													<Form.Item
														name="fileid"
													>
														<Upload
															customRequest={handleFileUpload}
															fileList={fileList}
															onChange={({ fileList }) => setFileList(fileList)}
														>
															<Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>Click to Upload</Button>
														</Upload>
													</Form.Item>
												</div>
												<div class="form-group row">
													<label for="slug" class="col-12 col-form-label">Title</label>
													<div class="col-12">
														<input id="slug" name="slug" class="form-control here set-slug" type="text" value={title} onChange={(e) => setTitle(e.target.value)} autoComplete="off" />
													</div>
												</div>

												<div className="form-group row">
													<label className="col-12 col-form-label">Description</label>
													<div className="col-12">
														<CKEditor
															editor={ClassicEditor}
															data={description}
															onChange={(event, editor) => {
																let newDescription = editor.getData();

																// Sanitize the editor data and remove <p> tags
																const sanitizedDescription = DOMPurify.sanitize(newDescription, {
																	ALLOWED_TAGS: ['b', 'i', 'u', 'strong', 'em', 'a', 'br', 'ul', 'ol', 'li'], // Allow only certain tags
																	ALLOWED_ATTR: ['href', 'target'], // Allow specific attributes if needed
																});

																// Optionally, remove <p> tags specifically if needed
																const finalDescription = sanitizedDescription.replace(/<\/?p[^>]*>/g, "");

																// Set the sanitized description without <p> tags
																setDescription(finalDescription);
															}}
														/>
													</div>
												</div>

												<div class="form-group row">
													<label class="col-12 col-form-label">Event Link</label>
													<div class="col-12">
														<input id="slug" name="slug" class="form-control here set-slug" type="text" value={eventlink} onChange={(e) => setEventlink(e.target.value)} autoComplete="off" />
													</div>
												</div>

												<div class="form-group row">
													<label class="col-12 col-form-label">Date</label>
													<div class="col-12">
														<DatePicker
															onChange={(dateString) => setDate(dateString)}
															value={date}
															format="YYYY-MM-DD"
															placeholder="Select Date"
														/>
													</div>
												</div>
												<div className="col-md-12">
													<label className="form-label" name="fileid">Multiple Images Upload</label>
													<Form.Item
														name="fileid"
													>
														<Upload
															customRequest={handleFileUploadforMultipleImages}
															fileList={fileList2}
															onChange={({ fileList2 }) => setFileList2(fileList2)}
														>
															<Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>
																Click to Upload
															</Button>
														</Upload>
													</Form.Item>
												</div>

												<div class="row">
													<div class="col-12">
														<button name="submit" type="submit" class="btn btn-primary">Submit</button>
													</div>
												</div>

											</form>

										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-8 col-lg-12">
								<div class="ec-cat-list card card-default">
									<div class="card-body">
										<div class="table-responsive">
											<table id="responsive-data-table" class="table">
												<thead>
													<tr>
														<th>Title</th>
														<th>Description</th>
														<th>Event Link</th>
														<th>Date</th>
														<th>Event Image</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{data.map((category) => (
														<tr key={category.categoryid}>
															<td>{category.title}</td>
															<td dangerouslySetInnerHTML={{ __html: category.description }}></td>
															<td>{category.eventlink}</td>
															<td>{category.date}</td>
															<td>
																{category.eventimage && (
																	<img
																		src={category.eventimage}
																		alt={`EventImage`}
																		width="100"
																		height="100"
																	/>
																)}
															</td>
															<td>
																<div className="btn-group">
																	<a
																		href="#"
																		className="btn btn-outline-success"
																		onClick={(e) => {
																			e.preventDefault(); // Prevent the anchor tag from navigating
																			handleEditCategory(category);
																		}}
																	>
																		Edit
																	</a>
																	<a
																		href="#"
																		className="btn btn-outline-danger"
																		onClick={(e) => {
																			e.preventDefault(); // Prevent the anchor tag from navigating
																			handleDeleteCategory(category.eventid);
																		}}
																	>
																		Delete
																	</a>
																</div>                </td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{selectedCategory && (
					<EditSubCategoryModal
						show={showEditModal}
						onHide={handleCloseEditModal}
						onSave={handleSaveEdit}
						categoryList={categoryList}
						category={selectedCategory}
						handleFileUpload={handleFileUpload}
					/>
				)}
			</div>

		</div>

	);
}
export default SubCategory;